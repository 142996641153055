import axios from 'axios';
import { SIGNUP_SUCCESS, SIGNUP_REQUEST,SIGNUP_FAILURE } from './types';



export const signupRequest = (name,customer_email,customer_password) => {


  return  (dispatch) => {
    dispatch({ type: SIGNUP_REQUEST });

    axios.post('http://arapis.gosmart.ae/customer_sign_up', {
        name:name,
        customer_email:customer_email ,
        customer_password:customer_password 
    },{
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any additional headers if needed
        }
      }
    
    )

    .then(response => {
        console.log(response.data)
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: response.data
        });
      })

      .catch(error => {
        dispatch({
          type:SIGNUP_FAILURE,
          payload: error.message
        });
      });
  };
};

