import React, { useState } from 'react'
import horizontal1pic from "/webapps/gosmart_landingpage/src/assets/Mask Group 157.png";
import "./style/signup.css"
import { Link } from 'react-router-dom';
import { signupRequest } from './Redux/Actions/AuthActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setSuccessMessage, setErrorMessage } from './Redux/Actions/AuthActions';


const  Signup = ({loading, error, signupRequest}) => {



  const [showPassword, setShowPassword] = useState(false);
  const [name, SetName]= useState('');
  const [password, setPassword] = useState('');
  const [email , SetEmail]= useState('');
  const dispatch = useDispatch();
 

  const handleSubmit = (e) => {
    e.preventDefault();
    signupRequest(name,email,password);


  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div>


<div class="directory">

<h5>
  <Link style={{textDecoration:"none",color:"black"}} to={"/signin"}>Sign in  </Link>
   <span> / Create My Account</span></h5>




</div>


      <div class="container-fluid ">
        <div class="row">
          <div class="col-12 mt-3">
            <div class="card6 pb-5">
              <div class="card-horizontal3">
                <div class="card6-body">
                  <div class="titles">
                    <h4 class="card-title">Create an account </h4>
                  </div>

                  <form class="form">
                  <label class="Name" for="name">Name <span>*</span> </label>
                    <input required type="text" placeholder='Enter your Name' id="name" class="form-control" style={{ marginBottom: "20px" }} value={name} onChange={(e) => SetName(e.target.value)} 
                    />
                    <label class="Email" for="email">Email <span>*</span> </label>
                    <input required type="text" placeholder='Enter your mail address' id="email" class="form-control" style={{ marginBottom: "20px" }} value={email} onChange={(e) => SetEmail(e.target.value)} />
                    <label class="Email" for="country">What Is Your Company Based? <span>*</span> </label>
                    <select id="country" name="country" class="arrow form-control" >
                      <option value="Afghanistan">Afghanistan</option>
                      <option value="Åland Islands">Åland Islands</option>
                      <option value="Albania">Albania</option>
                    </select>

                  

                    <label class="Email" for="phonenumber">Phone Number <span>*</span> </label>
                    <input required type="text" placeholder='Enter your phone number' id="phonenumber" class="form-control" style={{ marginBottom: "20px" }} />

                    <div className='password-container'>
                      <label class="Password" for="password">Password <span>*</span></label>
                      <input required id="password" placeholder='Enter password'
                        value={password} type={showPassword ? 'text' : 'password'}onChange={(e) => setPassword(e.target.value)}
                        class="form-control"
                         />
                      <div
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? '👁️' : '👁️‍🗨️'}
                      </div>


                    </div>

                    <label className="label-container privacy-policy">
                      <input
                        type="checkbox"
                        name="acceptPrivacyPolicy"
                        required
                        class="checkbox" />



                      <h6>I Accept the <span>Privacy Policy</span></h6>
                    </label>


                    <button type="button" class="loginbtn btn btn-primary float-right mt-2"  onClick={handleSubmit}>Create an Account</button>

                    

  






                  </form>


                </div>



                <div class="right-side-container">
                  <img class="right2-img" src={horizontal1pic} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  error: state.auth.error,
});
export default connect(mapStateToProps, { signupRequest })(Signup);
