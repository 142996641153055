import { FETCH_DATA_FAILURE, FETCH_DATA_SUCCESS, FETCH_DATA_REQUEST } from './types';
import axios from 'axios';

export const loginSuccess = (message) => ({
  type: 'FETCH_DATA_SUCCESS',
  payload: message,
});

export const loginFailure = (error) => ({
  type: 'FETCH_DATA_FAILURE',
  payload: error,
});

export const fetchData = (email, password) => {
  return (dispatch) => {
    dispatch({ type: FETCH_DATA_REQUEST });

    axios.post('http://arapis.gosmart.ae/customer_login', { 
      email:email,
      password:password  
     }, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if needed
      }
    })
      .then(response => {
        console.log(response.data)
        dispatch(loginSuccess(response.data))

        dispatch({
          type: FETCH_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch(error => {
        dispatch(loginFailure(error.message));
        dispatch({
          type: FETCH_DATA_FAILURE,
          payload: error.message
        });
      });
  };
};







// async action creator using thunk
// export const fetchData = () => async (dispatch) => {
//   dispatch(fetchItemsRequest());

//   try {
//     const response = await axios.get('https://jsonplaceholder.typicode.com/posts');
//     dispatch(fetchItemsSuccess(response.data));
//   } catch (error) {
//     dispatch(fetchItemsFailure(error.message));
//   }
// };

//