const initialState = {
    user_email: '',
    subject: '',
    message: '',
    successMessage: '',
    errorMessage: '',
  };
  
  const formReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_EMAIL':
        return { ...state, user_email: action.payload };
      case 'SET_SUBJECT':
        return { ...state, subject: action.payload };
      case 'SET_BODY':
        return { ...state, message: action.payload };
      case 'SET_SUCCESS_MESSAGE':
        return { ...state, successMessage: action.payload, errorMessage: '' };
      case 'SET_ERROR_MESSAGE':
        return { ...state, errorMessage: action.payload, successMessage: '' };
      case 'RESET_FORM':
        return initialState;
      default:
        return state;
    }
  };
  
  export default formReducer;
  