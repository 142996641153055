import React from "react";
import { FETCH_DATA_FAILURE, FETCH_DATA_SUCCESS, FETCH_DATA_REQUEST } from "../Actions/types";

const initialState = {
  loading: false,
  error: '',
  data: ''
};

const SigninReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_DATA_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_DATA_SUCCESS':
      return { ...state, loading: false, data: action.payload, error: '' };
    case 'FETCH_DATA_FAILURE':
      return { ...state, loading: false, error: action.payload };
  
    default:
      return state;
  }
};

export default SigninReducer;