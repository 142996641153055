import Swal from 'sweetalert2'

export const setEmail = (user_email) => ({
    type: 'SET_EMAIL',
    payload: user_email,
  });
  
  export const setSubject = (subject) => ({
    type: 'SET_SUBJECT',
    payload: subject,
  });
  
  export const setBody = (message) => ({
    type: 'SET_BODY',
    payload: message,
  });

  export const setSuccessMessage = (message) => ({
    type: 'SET_SUCCESS_MESSAGE',
    payload: message,
  });
  
  export const setErrorMessage = (message) => ({
    type: 'SET_ERROR_MESSAGE',
    payload: message,
  });
  
  export const resetForm = () => ({
    type: 'RESET_FORM',
  });

  export const opensweetalert = () => 
  {
    Swal.fire({
      title: 'Thank you',
      text: "Email has been successfully sent",
      type: 'success',
      
    })
  }