import React, { useState } from 'react'
import '/webapps/gosmart_landingpage/src/Components/style/RequestERP.css'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import Sales from '/webapps/gosmart_landingpage/src/assets/icon.png'
import Invoicing from '/webapps/gosmart_landingpage/src/assets/invoicing.png'
import crm from '/webapps/gosmart_landingpage/src/assets/crm.png'
import Website from '/webapps/gosmart_landingpage/src/assets/web.png'
import Inventory from '/webapps/gosmart_landingpage/src/assets/inventory.png'
import Accounting from '/webapps/gosmart_landingpage/src/assets/accounting.png'
import eCommerce from '/webapps/gosmart_landingpage/src/assets/e-commerce.png'
import Time from '/webapps/gosmart_landingpage/src/assets/time.png'
import Purchase from '/webapps/gosmart_landingpage/src/assets/purchase.png'
import Manufacturing from '/webapps/gosmart_landingpage/src/assets/manuf.png'
import axios from 'axios';
import Modal from "react-modal";
import point_of_sale from '/webapps/gosmart_landingpage/src/assets/point-of-sale.png'
import Expenses from '/webapps/gosmart_landingpage/src/assets/expences.png'
import Maintenance from '/webapps/gosmart_landingpage/src/assets/maint.png'
import Employees from '/webapps/gosmart_landingpage/src/assets/employees.png'
import Recruitment from '/webapps/gosmart_landingpage/src/assets/recruit.png'
import Fleet from '/webapps/gosmart_landingpage/src/assets/fleet.png'
import Events from '/webapps/gosmart_landingpage/src/assets/events.png'
import Survery from '/webapps/gosmart_landingpage/src/assets/survey.png'
import Project from '/webapps/gosmart_landingpage/src/assets/project.png'
import TimeSheet from '/webapps/gosmart_landingpage/src/assets/time-sheet.png'
import Helpdesk from '/webapps/gosmart_landingpage/src/assets/helpdesk.png'
import Discuss from '/webapps/gosmart_landingpage/src/assets/discuss.png'
import Approvals from '/webapps/gosmart_landingpage/src/assets/approves.png'
import Forum from '/webapps/gosmart_landingpage/src/assets/forum.png'
import Live_Chat from '/webapps/gosmart_landingpage/src/assets/live-chat.png'



export default function RequestDemo() {
    const [selectedModules, setSelectedModules] = useState([]);
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false); // Track form submission state
    const [showModal, setShowModal] = useState(false);
    const [progress, setProgress] = useState(0);
    const [companyLogo, setCompanyLogo] = useState(null);

    const openModal = () => {
        setShowModal(true);
    };


    const closeModal = () => {
        setShowModal(false);
    };


    const toggleSelection = (module) => {
        if (selectedModules.includes(module)) {
            setSelectedModules(selectedModules.filter((item) => item !== module));
        } else {
            setSelectedModules([...selectedModules, module]);
        }
    };


    const handlePhoneChange = (value) => {
        setPhone(value);

    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            console.log("Selected file:", file);
            setCompanyLogo(file);
        }
    };


    const modules = [
        'CRM',
        'Sales',
        'Point of Sale',
        'Accounting',
        'Invoicing',
        'Expenses',
        'Inventory',
        'Manufacturing',
        'Purchase',
        'Maintenance',
        'Employees',
        'Recruitment',
        'Time Off',
        'Fleet',
        'Events',
        'Survery',
        'Project',
        'TimeSheet',
        'Helpdesk',
        'Discuss',
        'Approvals',
        'Website',
        'Forum',
        'Live Chat',

    ];

    const images = [
        crm,
        Sales,
        point_of_sale,
        Accounting,
        Invoicing,
        Expenses,
        Inventory,
        Manufacturing,
        Purchase,
        Maintenance,
        Employees,
        Recruitment,
        Time,
        Fleet,
        Events,
        Survery,
        Project,
        TimeSheet,
        Helpdesk,
        Discuss,
        Approvals,
        Website,
        Forum,
        Live_Chat,
    ];


    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent form default submission behavior

        // Reset states
        setErrorMessage("");
        setSuccessMessage("");
        setProgress(0);

        if (loading) return;

        // Validate fields
        if (!name || !email || !phone || !password || !confirmPassword || !companyName) {
            setErrorMessage("Please fill out all fields.");
            return;
        }

        if (companyName.includes(" ")) {
            setErrorMessage("Company name cannot contain spaces.");
            return;
        }

        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        // Open the modal and start loading
        openModal();
        setLoading(true);

        // Simulate progress
        let progressInterval = setInterval(() => {
            setProgress((prev) => {
                if (prev >= 90) {
                    clearInterval(progressInterval);
                    return prev;
                }
                return prev + 10; // Increment progress
            });
        }, 500);

        // Prepare the form data
        const formData = {
            name,
            email,
            phone,
            password,
            db_name: companyName,
            modules: selectedModules,
        };

        try {
            const response = await axios.post("http://37.27.29.12:10000/api/create_demo_db/", formData);

            if (response.status === 200) {
                setSuccessMessage("Demo request submitted successfully!");
                setErrorMessage("");
                setProgress(100); // Set progress to 100 when complete
            } else {
                throw new Error("Something went wrong.");
            }
        } catch (error) {
            setErrorMessage(error.message || "Error submitting the form. Please try again.");
            setSuccessMessage("");
        } finally {
            setLoading(false); // Stop loading
            clearInterval(progressInterval); // Clear interval
            setTimeout(() => {
                closeModal(); // Close modal after a short delay
            }, 2000);
        }
    };



    return (
        <section>
            <div class="section-header">
                <div class="container">
                    <h2>Request ERP Demo</h2>
                    <p>
                        Experience how our ERP solution can transform your business! Book a 7-day demo now, select your preferred modules, and explore the benefits. With a variety of modules available, we can even customize them to suit your specific needs. See the difference firsthand!


                    </p>
                </div>
            </div>

            <div class="Request-form container">

                <form action="" id="Request-form" onSubmit={openModal}>
                    {/* <h2 className='fill-data-h2'>Please Fill the Demo Data</h2> */}
                    <div className='grid-view-inputs'>


                        <div class="input-box">
                            <label>Company Name</label>
                            <input
                                type="text"
                                // required
                                name="db_name"
                                placeholder="Database Name"
                                className="form-control"
                                value={companyName}
                                onChange={handleCompanyNameChange}  // Handle change with validation
                            />                     </div>
                        <div class="input-box">
                            <label>Company Logo</label>
                            <input
                                type="file"
                                name="companyLogo"
                                className="form-control"
                                accept="image/*"
                                onChange={(e) => handleFileChange(e)}
                            />
                        </div>
                        <div class="input-box">
                            <label>Email</label>
                            <input
                                type="email"
                                // required
                                name="user_email"
                                placeholder="Email"
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />                        </div>
                        <div class="input-box">
                            <label>Phone</label>
                            <PhoneInput
                                country={"eg"}
                                value={phone}
                                onChange={handlePhoneChange}
                                className="phone-input"
                                required
                            />                    </div>

                        <div class="input-box">
                            <label>Password</label>

                            <input
                                type="password"
                                // required
                                name="password"
                                placeholder="Password"
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />                        </div>
                        <div class="input-box">
                            <label>Confirm Password</label>
                            <input
                                type="password"
                                // required
                                name="confirmpassword"
                                placeholder="Confirm Password"
                                className="form-control"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />                        </div>



                    </div>

                    <div className='mt-3'>
                        <label>Select Modules :</label>

                    </div>

                    <div className='modules-grid-view'>

                        {modules.map((module, index) => (
                            <div
                                key={index}
                                className={`single-module-flex-view ${selectedModules.includes(module) ? 'selected' : ''
                                    }`}
                                onClick={() => toggleSelection(module)}
                            >
                                <div className='odoo-icon-container'>
                                    <img src={images[index]} className='odoo-icon' />
                                </div>
                                <span className='spaning-module'>                                {module}
                                </span>

                            </div>
                        ))}
                    </div>
                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}

                    <div class="input-box mt-3">
                        <button className='submit-btn' type="submit" value="Send" name="" >
                            {loading ? "Submitting..." : "Book FREE Demo Now"}


                        </button>

                    </div>
                </form>

                {/*}  {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

          {/* <SuccessPopup isOpen={isPopupOpen} onClose={closePopup} /> */}
            </div>

            <Modal
                isOpen={showModal}
                onRequestClose={closeModal}
                contentLabel="Installing Modules"
                className="Modal"
                overlayClassName="Overlay"
            >
                <h2 className="success-title mb-3">Installing Modules</h2>


                {/* Progress Bar */}
                {loading && (
                    <div className="progress-container">
                        <div
                            className="progress-bar"
                            style={{ width: `${progress}%` }}
                        ></div>
                        <span>{progress}%</span>
                    </div>
                )}

                <div className="modal-actions-btns">
                    <button
                        className="btn btn-primary"
                        // onClick={handleInstallModules}
                        disabled={loading} // Disable button during installation
                    >
                        {loading ? "Installing..." : "Install Modules"}
                    </button>
                    <button className="btn btn-secondary" onClick={closeModal} disabled={loading}>
                        Close
                    </button>
                </div>
            </Modal>
        </section>

    )
}
