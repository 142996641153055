import { combineReducers } from 'redux';
import formReducer from './contactusReducer';
import SigninReducer from './singinreducer';
import authReducer from './AuthReducer';

const rootReducer = combineReducers({
  form : formReducer,
  signin : SigninReducer,
  auth : authReducer
});

export default rootReducer;