import React from "react";
import mobilebanner from "/webapps/gosmart_landingpage/src/assets/mobilebanner.png";
import tabletviewbanner from "/webapps/gosmart_landingpage/src/assets/tabletview.png";
import homebanner from "/webapps/gosmart_landingpage/src/assets/banner.png";
import layoutpic from "/webapps/gosmart_landingpage/src/assets/Group 1.svg";
import "./style/maincontent.css";
import { Link as ScrollLink } from 'react-scroll';
import onesvg from "/webapps/gosmart_landingpage/src/assets/01.svg";
import twosvg from "/webapps/gosmart_landingpage/src/assets/02.svg";
import threesvg from "/webapps/gosmart_landingpage/src/assets/03.svg";
import card1svg from "/webapps/gosmart_landingpage/src/assets/m8fedj.tif.svg";
import card2svg from "/webapps/gosmart_landingpage/src/assets/qM14rq.tif.svg";
import card3svg from "/webapps/gosmart_landingpage/src/assets/sA324Z.tif.svg";
import card4svg from "/webapps/gosmart_landingpage/src/assets/s2LKn3.tif.svg";
import card5svg from "/webapps/gosmart_landingpage/src/assets/xl6lW8.tif.svg";
import card6svg from "/webapps/gosmart_landingpage/src/assets/7RdIY4.tif.svg";
import card7svg from "/webapps/gosmart_landingpage/src/assets/Image 4@2x.png";
import card8svg from "/webapps/gosmart_landingpage/src/assets/Image 5@2x.png";
import card9svg from "/webapps/gosmart_landingpage/src/assets/Image 6@2x.png";
import card10svg from "/webapps/gosmart_landingpage/src/assets/Image 7@2x.png";
import card11svg from "/webapps/gosmart_landingpage/src/assets/Image 8@2x.png";
import card12svg from "/webapps/gosmart_landingpage/src/assets/xl6lW8.tif@2x.png";
import Horizontalcards from "./horizontalcards";


import Footer from "./footer";
import Partner from "./partner";
import Chat from "./Chat";

export default function Maincontent() {
  return (


    <div>


      <div class="banner-section" id="banner">
        <picture>
          <source media="(max-width:767px)" srcset={mobilebanner} />
          <source
            srcset={tabletviewbanner}
            media="(min-width: 768px) and (max-width: 1023px)"
          />
          <source srcset={homebanner} media="(min-width: 1024px)" />

          <img
            src={homebanner}
            class="img-banner-section"
            style={{ width: "100%" }}
          />
        </picture>


        <div class="layout">
          <span>Empowering businesses...</span>

          <h3>
            <div id="cardssection"></div>

            Elevate your Business with our Software Expertise.

          </h3>

          <a href=" " style={{ textDecoration: "none" }}>
            <ScrollLink class="nav-link active"
              to="cardssection"  // The ID of the element to scroll to (Footer component)
              smooth={true} // Enable smooth scrolling
              duration={500} // Scroll duration in milliseconds
            >

              <img src={layoutpic}


              />

            </ScrollLink>
          </a>



        </div>
      </div>

      <div class="container-fluid second-section pb-5 " >
        <div class="card1">
          <img class="app-img1" src={onesvg} alt="Card image cap" />
          <div class="bottom-left1">Our Mission</div>
          <div class="card-body1">
            <p class="card-text">
              Empowering businesses through seamless and scalable software
              solutions, we seamlessly transform ideas into reality,
              accelerating growth and driving innovation.
            </p>
          </div>
        </div>
        <div class="card1">
          <img class="app-img1" src={twosvg} alt="Card image cap" />
          <div class="bottom-left2">Our Vision</div>
          <div class="card-body1">
            <p class="card-text">
              To be the world's premier software development partner, empowering businesses to achieve their digital aspirations through our expertise , innovation , and unwavering commitment to success

            </p>
          </div>
        </div>
        <div class="card1">
          <img class="app-img1" src={threesvg} alt="Card image cap" />
          <div class="bottom-left3">Our Value</div>
          <div class="card-body1">
            <p class="card-text"  >
              Driven by excellence , innovation , and customer-centricity , We forge lasting partnerships , delivering exceptional software solutions that empower businesses to thrive .

            </p>


          </div>
        </div>
      </div >
      <Chat />

      <Partner />

      <div id="application" style={{paddingTop:"2rem", backgroundColor:"#f8fdff"}}></div>

      <div
        class=" applications pt-5 pb-5"
        style={{ backgroundColor: "#F8FDFF" }}
      >


        <div class="header-application-section">
          <h2>Applications</h2>

          <p>
            In software application development, we excel through innovative solutions, precision in execution, and a client-centric focus . Our commitment ensures tailored experiences,delivering excellence that surpasses expectations .

          </p>
        </div>

        <div class=" applications-cards-section">
          <div class="card2">
            <img class="app-img" src={card1svg} alt="Card image cap" />
            <div class="card2-body" >
              <h5 class="card2-title">Website</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                We specialize in developing dynamic and responsive websites , combining creativity and functionality to enhance our customers online presence and needs
              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card4svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Mobile Apps</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                We specialize in developing mobile apps , delivering tailored solutions that enhance user experience , engagement and business efficiency .
              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card2svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Computer Vision</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                We provide advanced computer vision solutions , leveraging innovative technologies and addressing clients' diverse needs .
              </p>
            </div>
          </div>

          <div class="card2">
            <img class="app-img" src={card3svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Full Stack</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                As full-stack developers , we master both frontend and backend technologies , delivering comprehensive and scalable solutions for our clients .
              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card5svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Docker & Pods & Containers</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                We Specialize in creating containerized solutions , optimizing deployment and scalability to meet our clients diverse requirements effectively .
              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card6svg} alt="Card image cap" />
            <div class="card2-body" >
              <h5 class="card2-title">Machine Learning</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                We deliver tailored  solutions IN Machine Learning , leveraging algorithms and data to meet clients unique needs .
              </p>

              <div id="techscroll"></div>
            </div>
          </div>
        </div >
      </div>

      <div
        class=" technologies pt-2 pb-5"
        style={{ backgroundColor: "#F8FDFF" }}
      >
        <div class="header-application-section" >
          <h2>TECHNOLOGIES</h2>

          <p>
            At our core, we leverage cutting-edge software technologies to drive innovation in development. Our commitment to staying at the forefront of the industry ensures that we deliver solutions crafted with the latest advancements, empowering your business with efficiency , scalability , and forward-looking capabilities

          </p>
        </div>

        <div class=" applications-cards-section">
          <div class="card2">
            <img class="app-img" src={card7svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Flutter</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                Flutter , a versatile open-resource UI software development , enables unified app development for mobile devices .
              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card8svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Python</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                Python , a powerful programming language , excels in simplicity , readability , and broad application domains .
              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card9svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Odoo</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                Odoo integrates seamlessly , streamlining business processes with modules covering CRM , Inventory , Accounting and more
              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card10svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Node</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                Node.js a fast , event-driven Javascript runtime , enables scalable and efficient server-side applications development .
              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card12svg} alt="Card image cap" />
            <div class="card2-body">
              <h5 class="card2-title">Docker</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                Docker revolutionizes software deployment , providing a lightweight and portable containerization platform for scalable application management .

              </p>
            </div>
          </div>
          <div class="card2">
            <img class="app-img" src={card11svg} alt="Card image cap" />
            <div class="card-body">
              <h5 class="card2-title">Word Press</h5>
              <p
                class="card-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#84818A",
                  paddingLeft: "10px",
                }}
              >
                WordPress , a widely-used content management platform , empowers creation and management of websites of diverse needs with robust features .
              </p>
            </div>
          </div>
        </div>
      </div>


      <div>
        <Horizontalcards />
      </div>
    </div>


  );
}
