import React from 'react'
import Navbaar from './Navbar'
import Footer from './footer'
import homebanner from "/webapps/gosmart_landingpage/src/assets/banner.png";
import "./style/contactus.css";
import emailjs from '@emailjs/browser';
import { useRef } from 'react';
import { useState } from 'react';
import SuccessPopup from './popup';
import { useDispatch , useSelector } from 'react-redux';
import { setSuccessMessage,setBody,setErrorMessage,setEmail,setSubject,resetForm , opensweetalert } from './Redux/Actions/contactusActions';
import ReCAPTCHA from "react-google-recaptcha";
import {Routes, Route, useNavigate} from 'react-router-dom';


const  ContactUS = () => {

  const navigate = useNavigate();


  const [capVal ,setCapVal]=useState(false);

  function onChange(value){
    console.log("captacha value" , value)
    setCapVal(true)
  }

  const dispatch = useDispatch();
  const { user_email, subject, message ,successMessage, errorMessage} = useSelector((state) => state.form);

  const handleChange = (event) => {
    const { name, value } = event.target;
    switch (name) {
      case 'user_email':
        dispatch(setEmail(value));
        break;
      case 'subject':
        dispatch(setSubject(value));
        break;
      case 'message':
        dispatch(setBody(value));
        break;
      default:
        break;
    }
  };

  // const[isPopupOpen, setIsPopupOpen] =useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform form submission logic here

    // Show the success popup
  //   setIsPopupOpen(true);
  // };

  setTimeout(() => {
    // Redirect to the home page
  navigate('/', {replace: true});
    window.location.reload();
  }, 7000);

  if(user_email && subject && message)
  {


    emailjs.send('service_nuv6ssd', 'template_yz3pbmq', {user_email, subject , message}, 'BFe3OaUH8Sb99gYlL')
    .then((response) => {
      // Reset form data
      dispatch(resetForm());
      dispatch(setSuccessMessage('Email sent successfully!'));
      dispatch(opensweetalert());

     
  })

  .catch((error) => {
    console.error('Error sending email:', error);
    // Display error message
    dispatch(setErrorMessage('Error sending email. Please try again later.'));
  });
} else {
// Display error message if fields are not filled
dispatch(setErrorMessage('Please fill in all fields.'));
}




  }

  // const closePopup = () => {
  //   // Close the popup
  //   setIsPopupOpen(false);
  // };

  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_xzfeuw6', 'template_d1iwd16', form.current, 'bKv_W3Rl6fSaETqbd')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //         console.log(error.text);
  //     });
  // };

 




  return (

    





    <section>
    
    <div class="section-header">
      <div class="container">
        <h2>Contact Us</h2>
        <p>For inquiries, technical support, or general assistance, please don't hesitate to get in touch with us.
Our dedicated team will contact you back, we appreciate the opportunity to assist you.</p>
      </div>
    </div>
    
    <div class="container">
      <div class="contactrow row">
        
        <div class="contact-info">
          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i style={{color:"#808080"}} class="fas fa-home"></i>
            </div>
            
            <div class="contact-info-content">
              <h4>Address</h4>
              <p>MBAM0330 Compass building , Al Shohada Road , <br/> AL Hamra industrial Zone  <br/>- FZ , RAK , UAE </p>
            </div>
          </div>
          
         
          
          <div class="contact-info-item">
            <div class="contact-info-icon">
              <i  style={{color:"#808080"}} class="fas fa-envelope"></i>
            </div>
            
            <div class="contact-info-content">
              <h4>Email</h4>
             <p>info@gosmart.ae</p>
            </div>
          </div>
        </div>
        
        <div class="contact-form">
          <form action="" id="contact-form" onSubmit={handleSubmit} >
            <h2>Send Email</h2>
            <div class="input-box">
              <input type="email" required="true" value={user_email} name="user_email"placeholder='Email'onChange={handleChange}/>
            </div>
            
            <div class="input-box">
              <input type="text" required="true" value={subject} name="subject" placeholder='Subject'onChange={handleChange}/>
            </div>
            
            <div class="input-box">
              <textarea required="true" value={message} name="message" placeholder='Body' onChange={handleChange}></textarea>
            </div>

            <ReCAPTCHA  sitekey="6Lc2p18pAAAAAB_h16hiw4bVXn-Fy7Rhe1B3dqqv
"
              onChange={val => setCapVal(val)}/>
            
            <div class="input-box">
              <input disabled={!capVal} required="true" type="submit" value="Send" name=""   />
              
            </div>
          </form>

        {/*}  {successMessage && <div style={{ color: 'green' }}>{successMessage}</div>}
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

          {/* <SuccessPopup isOpen={isPopupOpen} onClose={closePopup} /> */}
        </div>
        
      </div>
    </div>
{/*   
    <div class="popup" id="popup">
      <div id="popup-overlay"></div>
      <div class="popup-content">
        <div class="header">
          <h5>Awesome!</h5>
        </div>



        <p>Your booking has been confirmed. Check your email for detials.</p>

       

{/* 
        <div class="popup-footer">



          <div class="subtotal-header">

            <h5 style="float: left; padding-left: -auto; color:#1F3473; font-size: 20px;">Subtotal:</h5>
            <h5 style="float: right; color: #8D46A0; font-weight: 700; padding-right: 10px;">$99.99</h5>
          </div>


          <button class="btn1">
            VIEW CART
          </button>



          <button class="btn2">
            CHECKOUT
          </button>




        </div> */}


      





  </section>


    

  )
}

export default ContactUS ;
