import React, { useEffect } from 'react';
import "./style/horizontalcards.css";
import horizontal1pic from "/webapps/gosmart_landingpage/src/assets/Group 7.png";
import horizontal2pic from "/webapps/gosmart_landingpage/src/assets/Group 18@2x.png";
import { useDispatch, useSelector } from 'react-redux';

export default function Horizontalcards() {



return(

<div class=" first-left-card "
id='ourprojects'
    >

    <div class="container-fluid pt-5">
      <div class="row">
        <div class="col-12 mt-3">
          <div class="card4 pb-5">
            <div class="card-horizontal1">
              <div class="card4-body">
           
              {/* {data.map((post) => (
        <div key={post.id}>
          <h3>{post.title}</h3>
          <p>{post.body}</p>
        </div>
      ))} */}


                <h4 class="card4-title">Our Mobile Applications</h4>
                <p class="card4-text">With our team of experienced mobile app developers, we offer comprehensive
                  services that encompass the entire mobile app development lifecycle, from ideation and design to
                  development, testing, and deployment . </p>
              </div>

              <div class="img-square-wrapper">
                <img class="right-to-left-card-img" src={horizontal1pic} alt="Card image cap"/>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>


    <div class="container-fluid" >
      <div class="row">
        <div class="first-cont col-12 mt-3">
          <div class="card5">
            <div class="card-horizontal2">
              <div class="img-square-wrapper">
                <img class="left-to-right-card-img" src={horizontal2pic} alt="Card image cap"/>
              </div>
              <div class="card5-body">
                <h4 class="card5-title">Websites & Full Stack</h4>
                <p class="card5-text">Our full-stack development services encompass the entire software development lifecycle , from ideation and design to development , deployment , and maintenance. we seamlessly blend front-end finesse with back-end proficiency , ensuring your software solutions are not only visually captivating but also robust and scalable .
                  </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>












  </div>



);

}
