import React, { createContext, useState } from "react";

// Create the ChatContext
export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [email, setEmail] = useState(localStorage.getItem("email") || "");

  const updateEmail = (newEmail) => {
    setEmail(newEmail);
    localStorage.setItem("email", newEmail);
  };

  return (
    <ChatContext.Provider value={{ email, setEmail: updateEmail }}>
      {children}
    </ChatContext.Provider>
  );
};
