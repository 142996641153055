import React, { useState } from 'react'
import './style/signin.css'
import horizontal1pic from "/webapps/gosmart_landingpage/src/assets/Rectangle 62.png";
import { Link, json } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { fetchData } from './Redux/Actions/signinActions';



  const Signin = ({ loading, data, error, fetchData }) => {

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleVerification = () => {
    fetchData(email, password);


  };

  return (
    <div>

      <div class="directory">

        <h5>
          <Link style={{ textDecoration: "none", color: "black" }} to={"/"}>Home  </Link>
          <span> / My Account</span></h5>




      </div>


      <div class="container-fluid ">
        <div class="row">
          <div class="col-12 mt-3">
            <div class="card6 pb-5">
              <div class="card-horizontal3">
                <div class="card6-body">
                  <div class="titles">
                    <h4 class="card-title">Welcome back !</h4>
                    <p class="card-text">Enter to get unlimited access to data & informations.</p>
                  </div>

                  <form class="form">
                 
                    <label class="Email" for="username">Email <span>*</span> </label>
                    <input required type="text" placeholder='Enter your mail address' id="username" class="form-control" style={{ marginBottom: "20px" }} onChange={(e) => setEmail(e.target.value)}
                    />

                    <div className='password-container'>
                      <label class="Password" for="password">Password <span>*</span></label>
                      <input required id="password" placeholder='Enter password' value={password} type={showPassword ? 'text' : 'password'} class="form-control" onChange={(e) => setPassword(e.target.value)} />
                      <div
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? '👁️' : '👁️‍🗨️'}
                      </div>

                      <div>
                        <Link to="/forgetpassword">

                          <a href='' class="forgetpassword" >
                            Forget Password ?
                          </a>
                        </Link>
                      </div>
                    </div>

                    <button onClick={handleVerification} disabled={loading} type="button" class="loginbtn btn btn-primary float-right mt-2">
                              {loading ? 'Verifying...' : 'Login'}

                      </button>
                      {data && <p> {JSON.stringify(data.message)}</p>}                     

                  </form>

                  <div style={{ justifyContent: "center", textAlign: "center" }}>


                    <span class="signup" > Or Sign Up</span>

                  </div>

                  <Link to="/signup">

                    <button type="button" class="createbtn btn btn-primary float-right mt-2">Create New Account</button>
                  </Link>

                </div>



                <div class="right-side-container">
                  <img class="right-img" src={horizontal1pic} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>






    </div>
  )

  
}

const mapStateToProps = state => ({
  loading: state.signin.loading,
  data: state.signin.data,
  error: state.signin.error

});

const mapDispatchToProps = {
  fetchData
};

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
