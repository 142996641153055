import React, { useContext, useEffect, useState } from "react";
import chaticon from '/webapps/gosmart_landingpage/src/assets/Group 62.png'
import '/webapps/gosmart_landingpage/src/Components/style/Chat.css'
import Modal from "react-modal";
import chatbox from '/webapps/gosmart_landingpage/src/assets/WappGPT - logo.png'
import minus from '/webapps/gosmart_landingpage/src/assets/minus-cirlce.png'
import plus from '/webapps/gosmart_landingpage/src/assets/close-circle.png'
import chaticon2 from '/webapps/gosmart_landingpage/src/assets/chat.png'
import { ChatContext } from './ChatContext';

export default function Chat() {

    const [showModal, setShowModal] = useState(false);
    const [FlagingChatbox, SetFlagingChatbox] = useState(false)
    const [minimized, setMinimized] = useState(false); // State to handle the minimized state
    const { email, setEmail } = useContext(ChatContext);

    useEffect(() => {
        if (email) {
          // If email already exists, open the chat box directly
          SetFlagingChatbox(true);
        }
      }, [email]);


    const openModal = () => {
        if (!email) {
            // Open the modal only if email is not present
            setShowModal(true);
          } else {
            // Otherwise, open the chat box directly
            SetFlagingChatbox(true);
          }
        };
        const Openchatbox = () => {
            if (email.trim()) {
              SetFlagingChatbox(true);
              closeModal();
            } else {
              alert("Please enter a valid email");
            }
          };

    const handleMinimize = () => setMinimized(!minimized); // Toggle minimized state

    const closeModal = () => {
        setShowModal(false);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    
    }
    return (
        <>
            {!FlagingChatbox && (
                <div className='Chat-icon-alignment'>
                    <button onClick={openModal}>
                        Start Chat 

                        <img src={chaticon} />

                    </button>

                </div>
            )}
            {FlagingChatbox && (
                <div className='chatbox-container'>

                    <div className='blue-top-area' onClick={handleMinimize}>

                        <div className='flexing-top-area'>
                            <img src={chatbox} />
                            <h2 className='Title-top-area'>Go Smart Chat</h2>

                        </div>


                        {/* <div className='float-right-icon'>

                        <img 
                        
                        src={minimized ? plus : minus} // Conditionally switch between plus and minus icon
                        style={{ cursor: 'pointer' }} />

                        </div> */}


                    </div>
                    {!minimized && (
                        <>
                    <div className="chat-history">
                        {/* <div className="no-messages">
    <p style={{ fontSize: "16px" }}>No chat history</p>
  </div> */}
                        <div className="message-Client">
                            <p>Sample client message</p>

                        </div>
                        <small className="timestamp client-timestamp">
                            01/01/2023, 12:00 PM
                        </small>

                        <div className="message-System">
                            <p>Sample system message</p>

                        </div>
                        <small className="timestamp system-timestamp">
                            01/01/2023, 12:05 PM
                        </small>

                  
                    </div>
                    <div className='flexing-message-input'>
                        {/* <label htmlFor="fileInput" style={{ cursor: 'pointer', fontSize: "16px", marginTop: "1rem", marginRight: "0.5rem" }}>📎</label> */}
                        <input
                            type="text"
                            placeholder="Enter Your Message"
                            className='form-control'
                            style={{ fontSize: "16px" }}
                        />
                        <button>➤</button>
                    </div>
                    </>
                    )}
                </div>


            )}


            <Modal
                isOpen={showModal}
                onRequestClose={closeModal}
                contentLabel="Login Successful"
                className="Modal"
                overlayClassName="Overlay"
            >
                <div className="success-modal-container">
                    {/* Verification Image */}
                    <img
                        src={chaticon2}
                        alt="Verified"
                        className="verification-img"
                    />
                </div>

                {/* Success Message */}
                <h2 className="success-title mb-3">Please Subscibe for Chating with us</h2>

                <div className='single-input'>

                    {/* <label>Please Enter Your Name</label> */}
                    <input className='form-control' value={email} onClick={handleEmailChange}  required placeholder='Email' />

                    <button className='continue-btn' onClick={Openchatbox}> Continue </button>

                </div>





            </Modal>
        </>
    )
}
