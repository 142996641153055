import axios from 'axios';
import { SIGNUP_FAILURE,SIGNUP_SUCCESS,SIGNUP_REQUEST } from '../Actions/types';

const initialState = {
    loading: false,
    error: null,
    
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SIGNUP_REQUEST':
        return {
          ...state,
          loading: true,
          error: null,
        };
      case 'SIGNUP_SUCCESS':
        return {
          ...state,
          loading: false,
          error: null,
        };
      case 'SIGNUP_FAILURE':
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
  